<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        404
      </h2>

      <p class="b-text _center">
        Page not found.
      </p>
    </div>
  </Outer>
</template>

<script>
import Outer from '@/components/Outer.vue';

export default {
  components: {Outer},
};
</script>
